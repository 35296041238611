import { http, authHeader } from "@/http-commons";

class CashRegisterDataService {

    openCashRegister(cashRegister){
        return http.post(`api/v1/cashRegister/openCashRegister`, cashRegister, { headers: authHeader });
    }
    closeCashRegister(closedCashRegister){
        return http.post(`api/v1/cashRegister/closeCashRegister`, closedCashRegister, { headers: authHeader });
    }

    findCashRegisterOpenedByUser(userId){
        return http.get(`api/v1/cashRegister/findCashRegisterOpenedByUser/${userId}`, { headers: authHeader });
    }

    findCashRegisterOpenedByUserAndSucursal(userId, sucursalId){
        return http.get(`api/v1/cashRegister/findCashRegisterOpenedByUserAndSucursal/${userId}/${sucursalId}`, { headers: authHeader });
    }

    table( params ){
        return http.get('api/v1/cashRegister/table', { params, headers : authHeader() });
    }
    printCashRegister(cashRegisterId){
        return http.get(`api/v1/cashRegister/printCashRegister/${cashRegisterId}`, { headers : authHeader(), responseType: 'blob' });
    }
    printCashRegisterWithPrinter(cashRegisterId){
        return http.get(`api/v1/cashRegister/printCashRegisterWithPrinter/${cashRegisterId}`, { headers : authHeader(), responseType: 'blob' });
    }
}

export default new CashRegisterDataService();

