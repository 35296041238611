<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-tabs background-color="blue-grey lighten-4"
                    color="teal darken-3" grow v-model="tab"
                    class="text-h6 text-white">
                    <v-tab v-for="item in itemTabs" :key="item.value">
                        <h4>{{ item.text }}</h4>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item tab="cashRegisterForm" v-if="isSeller">
                        <CashRegisterForm :user-id="getUserId" :sucursal-id="getSucursalId"
                        :lst-cash-register.sync="lstCashRegister"
                        :has-cash-register-opened.sync="hasCashRegisterOpened"
                        @opened-cash-register="addCashRegister"
                        v-if="!hasCashRegisterOpened"/>

                        <ClosedCashRegister
                        :user-id="getUserId" :sucursal-id="getSucursalId"
                        :lst-cash-register="lstCashRegister"
                        :cash-register.sync="lstCashRegister[0]"
                        :has-cash-register-opened="hasCashRegisterOpened"
                        @closed-cash-register="removeCashRegister"
                        v-else/>
                    </v-tab-item>
                    <v-tab-item tab="corte">
                        <ListCashRegister />
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import UsuarioDataService from '@/service/usuario/usuarioDataService';
import CashRegisterForm from './CashRegisterForm.vue';
import ClosedCashRegister from './ClosedCashRegister.vue';
import ListCashRegister from './ListCashRegister.vue';
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import CashRegisterDataService from '@/service/cashRegister/CashRegisterDataService';

export default {
    name: "CashRegister",
    components: { CashRegisterForm, ListCashRegister, ClosedCashRegister },
    data(){
        return {
            tab: 'cashRegisterForm',
            lstCashRegister: [],
            itemTabs: [
                { text: 'APERTURA/CIERRE DE CAJA', value: 'cashRegisterForm' },
                { text: 'CORTES', value: 'corte' }
            ]
        }
    },
    created() {
        const userId = this.getUserId;
        this.findCashRegisterOpenedByUser(userId);
    },
    methods: {
        findCashRegisterOpenedByUser(userId) {
            CashRegisterDataService.findCashRegisterOpenedByUser(userId).then(response => {
                this.lstCashRegister = response.data;
            }).catch( error => {
                this.$swal.fire({ title: 'Error', text: 'No se pudo procesar la petición, ' +error, icon: 'error', showConfirmButton: false, timer: 2000 })
            }).finally(() => {
                if(!this.isSeller){
                    this.itemTabs.splice(0, 1);
                    this.tab = 'corte';
                }
            })
        },
        addCashRegister(cashRegister) {
            this.lstCashRegister.push(cashRegister);
        },
        removeCashRegister(cashRegister) {
            this.lstCashRegister = this.lstCashRegister.filter( cashReg => cashReg.id !== cashRegister.id);
        }
    },
    computed: {
        getUserId() { return UsuarioDataService.getCurrentUser().id; },
        getSucursalId() { return SucursalDataService.getCurrentSucursalId(); },
        hasCashRegisterOpened() {
            if(this.lstCashRegister.length === 0) return false;
            return this.lstCashRegister.some(cashRegister => cashRegister.status === 'OPENED');
        },
        isSeller(){ return UsuarioDataService.getUserRoles().includes('ROLE_VENDEDOR'); },
    }
}
</script>