<template>
    <v-row justify="center">
        <v-card class="elevation-5 mt-5 mb-15 teal lighten-5">
            <v-card-title>
                <h5 class="text text-h6 font-weight-bold">CERRAR CAJA DEL USUARIO {{ cashRegister.user }}. FECHA {{ cashRegister.opened }} </h5>
            </v-card-title>
            <ValidationObserver ref="obsClosedCashRegister">
                <v-form name="form" @submit.prevent="saveClosedCashRegister">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="pa-0">
                                <SingleCalendar refElement="openedReverse" title="Fecha de apertura" :input-date.sync="cashRegister.openedReverse"
                                :disabled="true" :clearable="false"/>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label="Total tickets contado*" v-model="amountCash" prepend-icon="fas fa-cash-register"
                                required counter disabled>
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field label="Total cobranza*" v-model="amountCollect" prepend-icon="fas fa-balance-scale"
                                required counter disabled>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-list-item class="grow">
                            <v-row justify="start" align="center">
                                <v-btn class="primary ma-2 pa-5" type="submit">
                                    <v-icon class="ma-2" dense large>fas fa-cash-register</v-icon> Cerrar caja
                                </v-btn>
                            </v-row>
                        </v-list-item>
                    </v-card-actions>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-row>
</template>
<script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import CashRegisterDataService from '@/service/cashRegister/CashRegisterDataService';
import VentaDataService from '@/service/venta/VentaDataService';
import VentaPagoDataService from '@/service/venta/pago/VentaPagoDataService';

export default {
    name: "ClosedCashRegister",
    components: { SingleCalendar },
    props: {
        userId: { type: Number, default: 0 },
        sucursalId: { type: Number, default: 0 },
        lstCashRegister: { type: Array, default: () => [] },
        cashRegister: { type: Object, default: () => {} },
        hasCashRegisterOpened: { type: Boolean, default: false }
    },
    created() {
        this.closedCashRegister = this.cashRegister
        this.findVentaByCashRegister(this.cashRegister.id)
    },
    data() {
        return {
            lstVenta: [],
            lstVentaPago: [],
            closedCashRegister: {}
        }
    },
    methods: {
        saveClosedCashRegister() {
            this.closedCashRegister.status = "CLOSED"
            CashRegisterDataService.closeCashRegister(this.closedCashRegister).then( _ => {
                this.$swal.fire({ title: 'Caja cerrada', text: 'Caja cerrada exitosamente', icon: 'success', showConfirmButton: false, timer: 2000 })
                this.$emit('closed-cash-register', this.closedCashRegister)
            }).catch( error => {
                this.$swal.fire({ title: 'Error', text: 'No se pudo procesar la petición, ' +error, icon: 'error', showConfirmButton: false, timer: 2000 })
            })
        },
        findVentaByCashRegister(cashRegisterId){
            VentaDataService.findByCashRegister(cashRegisterId).then( response => {
                this.lstVenta = response.data
            }).catch( error => {
                this.$swal.fire({ title: 'Error', text: 'No se pudo procesar la petición, ' +error, icon: 'error', showConfirmButton: false, timer: 2000 })
            });
        },
        findVentaPagoByCashRegister(cashRegisterId){
            VentaPagoDataService.findByCashRegister(cashRegisterId).then( response => {
                this.lstVentaPago = response.data
            }).catch( error => {
                this.$swal.fire({ title: 'Error', text: 'No se pudo procesar la petición, ' +error, icon: 'error', showConfirmButton: false, timer: 2000 })
            });
        }
    },
    watch: {
        cashRegister: {
            handler(newVal, oldVal) {
                this.closedCashRegister = newVal;
            },
            deep: true
        }
    },
    computed: {
        filterVentaByCash(){ return this.lstVenta.filter( sell => sell.tipoPago.id === 1); },
        filterVentaByCredit(){ return this.lstVenta.filter( sell => sell.tipoPago.id === 2); },
        amountCredit(){ return this.filterVentaByCredit.reduce((total, sell) => total += sell.total, 0); },
        amountCash(){ return this.filterVentaByCash.reduce((total, sell) => total += sell.total, 0); },
        amountCollect(){ return this.lstVentaPago.reduce((total, ventaPago) => total += ventaPago.payedAmount, 0) }
    }
}
</script>