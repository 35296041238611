<template>
    <v-row justify="center">
        <v-card class="evelation-5 mt-5 mb-15 teal lighten-5">
            <v-card-title>
                <h5 class="text text-h6 font-weight-bold">ABRIR CAJA, USUARIO {{ cashRegister.user }}. FECHA {{ aperturaNatural }} </h5>
            </v-card-title>
            <ValidationObserver ref="obsCashRegister" v-slot="{ invalid, validated }">
                <v-form name="form" @submit.prevent="saveCashRegister">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" class="pa-0">
                                <SingleCalendar refElement="paymentDate" title="Fecha de apertura" :input-date.sync="cashRegister.apertura" :disabled="true"/>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider rules="required|decimal" v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field label="Fondo fijo de caja*" v-model="cashRegister.fondoFijo" prepend-icon="fas fa-cash-register"
                                    required :error-messages="errors" :success="valid" counter>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider rules="required|decimal" v-slot="{ errors, valid }" :immediate="true">
                                    <v-text-field label="Fondo de gastos*" v-model="cashRegister.fondoGasto" prepend-icon="fas fa-balance-scale"
                                    required :error-messages="errors" :success="valid" counter>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                        <v-spacer></v-spacer>
                    </v-card-text>
                    <v-card-actions>
                        <v-list-item class="grow">
                            <v-row justify="start" align="center">
                                <v-btn class="primary ma-2 pa-5" type="submit" :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large>fas fa-cash-register</v-icon> Abrir caja
                                </v-btn>
                            </v-row>
                        </v-list-item>
                    </v-card-actions>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-row>
</template>
<script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import CashRegisterDataService from '@/service/cashRegister/CashRegisterDataService';
import { getDateLocaleNatural, getToday } from '@/utils/DateUtil';

export default {
    name: "CashRegisterForm",
    components: {
        SingleCalendar
    },
    props: {
        userId: { type: Number, default: 0 },
        sucursalId: { type: Number, default: 0 },
        lstCashRegister: { type: Array, default: () => [] },
        hasCashRegisterOpened: { type: Boolean, default: false }
    },
    data() {
        return {
            cashRegister: {
                id: null,
                apertura: getToday().toFormat('yyyy-MM-dd'),
                userId: null,
                sucursalId: null,
                status: 'OPENED',
                fondoFijo: 2000,
                fondoGasto: 2000,
            }
        }
    },
    methods: {
        async saveCashRegister() {
            this.cashRegister.userId = this.userId;
            this.cashRegister.sucursalId = this.sucursalId;

            if(this.hasCashRegisterOpened){
                this.$swal.fire({ title: 'Caja abierta', icon: 'info', text: 'Ya hay una caja abierta, favor de cerrar en caso de ser necesario', timer: 6000 });
                return;
            }
            if(this.sucursalId == null && this.sucursalId == 0){
                this.$swal.fire({ title: 'Sucursal necesaria', icon: 'info', text: 'Para abrir una caja es necesario que su cuenta este asignado a una sucursal',  timer: 4000 });
                return;
            }
            this.$refs.obsCashRegister.validate().then(valid => {
                if (valid) {
                    CashRegisterDataService.openCashRegister(this.cashRegister).then( result => {
                        if( result.status === 200 || result.status === 201 ){
                            this.$swal.fire({ icon: 'success', title: 'Caja abierta', text: 'Caja abierta correctamente, continua con sus ventas...',  timer: 4000 });
                            this.$emit('opened-cash-register', result.data);
                        }
                    }).catch( error => {
                            this.$swal.fire({ icon: 'error', title: 'Error', text: 'No se pudo procesar la petición, ' +error.message, showConfirmButton: false, timer: 4000 });
                    })
                }
            });
        }
    },
    computed: {
        aperturaNatural() {
            return getDateLocaleNatural(this.cashRegister.apertura);
        }
    }
}
</script>