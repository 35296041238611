<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-5">
                <v-card-title>
                    <v-toolbar flat>                
                        <v-spacer></v-spacer>
                        <SingleCalendar refElement="activeInicio" :input-date.sync="datesRange.start" title="Fecha inicio"/>
                        <SingleCalendar refElement="activeFin" :input-date.sync="datesRange.end" title="Fecha fin" :min-date="datesRange.start"/>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="6" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                        label="Buscar corte de caja" single-inline hide-details
                        @keydown.enter="searchListCashRegister">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <v-tooltip left color="success" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mt-4 ml-2" color="success" v-bind="attrs" v-on="on" dark @click="handleExcelCashRegister">
                                    <v-icon dark left>fas fa-file-excel</v-icon>
                                </v-btn>
                            </template>
                            <span>Reporte de caja</span>
                        </v-tooltip>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="lstCashRegister" class="elevation-2"
                :loading="loading" loading-text="Cargando... espera un momento" :options.sync="options"
                :server-items-length="totallstCashRegister"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ],
                    showFirstLastPage: true,
                    firstIcon: 'fas fa-angle-double-left',
                    lastIcon: 'fas fa-angle-double-right',
                    prevIcon: 'fas fa-angle-left',
                    nextIcon: 'fas fa-angle-right'
                }">
                    <template v-slot:[`item.actions`] ="{ item }">
                        <ActionsCashRegister :item="item"/>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import Columns from '@/components/shared/Columns.vue';
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import CashRegisterDataService from '@/service/cashRegister/CashRegisterDataService';
import { getToday } from '@/utils/DateUtil';
import { getParamsPagination } from '@/utils/pagination';
import ActionsCashRegister from './ActionsCashRegister.vue';


export default {
    name: "ListCashRegister",
    components: { SingleCalendar, Columns, ActionsCashRegister },
    data(){
        return {
            datesRange: {
                start: getToday().toFormat('yyyy-MM-dd'),
                end: getToday().toFormat('yyyy-MM-dd')
            },
            lstCashRegister: [],
            options: {},
            totallstCashRegister: 0,
            loading: true,
            search: '',
            headers: [
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text: 'ID', align: "", sortable: false, value: 'id', visible: false, groupable: false, show: false },
                { text: 'Sucursal', value: 'sucursal', sortable: false, groupable: false, show: true },
                { text: 'Usuario', value: 'user', sortable: false, groupable: false, show: true },
                { text: 'Fecha apertura', value: 'opened', sortable: false, groupable: false, show: true },
                { text: 'Fecha cierre', value: 'closed', sortable: false, groupable: false, show: true },
                { text: 'Estatus', value: 'status', sortable: false, groupable: false, show: true },
                { text: 'Total', value: 'total', sortable: false, groupable: false, show: true },
            ]
        }
    },
    created(){
        this.initTableCashRegister();
    },
    methods: {
        searchListCashRegister(){
            this.options.page = 1;
            this.initTableCashRegister(this.options);
        },
        handleExcelCashRegister(){
            
        },
        initTableCashRegister(options = {}){
            this.loading = true;
            let params = getParamsPagination({ options, search: this.search,
                params: { startDate: this.datesRange.start, endDate: this.datesRange.end },
            });
            CashRegisterDataService.table(params).then( resp => {
                const { rows, totalElements } = resp.data;
                this.lstCashRegister = rows;
                this.totallstCashRegister = totalElements;
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Falló la carga de información', text: err.response.data, timer: 5000 });
            }).finally( () => {
                this.loading = false;
            })
        }
    },
    computed:{ 
        filteredHeaders(){ return this.headers.filter( h => h.show ); },
    }
}
</script>