import { http, authHeader } from "@/http-commons";

class VentaPagoDataService {

    store(ventaPago){
        return http.post("/api/v1/ventaPago/store", ventaPago, authHeader());
    }
    table(params){
        return http.get("/api/v1/ventaPago/table", {params, headers: authHeader()});
    }
    findByVentaPago(ventaPagoId){
        return http.get(`/api/v1/ventaPago/findByVentaPago/${ventaPagoId}`, {headers: authHeader()});
    }
    findByCashRegister(cashRegisterId){
        return http.get(`/api/v1/ventaPago/findByCashRegister/${cashRegisterId}`, { headers: authHeader() });
    }
    printPayment(paymentId){
        return http.get(`/api/v1/ventaPago/printPayment/${paymentId}`, { headers: authHeader(), responseType: 'blob' });
    }
    downloadExcelPago(params){
        return http.get("/api/v1/ventaPago/downloadPagos", {params, headers: authHeader(), responseType: 'blob' });
    }
}

export default new VentaPagoDataService();